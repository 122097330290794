<template>
  <div class="foot">
    <div class="footer">
      <div class="footer_center">
        <ul class="footer_ul">
          <li>关于我们</li>
          <li>联系我们</li>
          <li>加入我们</li>
          <li>隐私声明</li>
          <li>网站地图</li>
          <li>友情链接</li>
        </ul>
        <div class="kefu">官方客服 4006768400</div>
        <div class="footer_border_bottom"></div>
        <div class="footer_shopHouse">
          <span>商圈二手房</span>
          <span>热门二手房</span>
          <span>二手房</span>
          <span>推荐楼盘</span>
        </div>
        <div class="footer_shopHouseUrl">
          <span>郑州二手房</span>
          <span>漯河二手房</span>
          <span>开封二手房</span>
          <span>驻马店二手房</span>
          <span>许昌二手房</span>
          <span>鹤壁二手房</span>
          <span>新乡二手房</span>
          <span>周口二手房</span>
          <span>洛阳二手房</span>
          <span>南阳二手房</span>
          <span>信阳二手房</span>
          <span>安阳二手房</span>
          <span>商丘二手房</span>
          <span>三门峡二手房</span>
          <span>焦作二手房</span>
          <span>上海二手房</span>
          <span>北京二手房</span>
          <span>江苏二手房</span>
          <span>西安二手房</span>
        </div>
        <div class="footer_certificateNumber"><a href="https://beian.miit.gov.cn/">ICP证号：豫ICP备18033663号-1</a></div>
        <div class="footer_certificateNumber">河南金银谷智能网络科技有限公司</div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.foot{
  clear:both;
  bottom:0;
  background-color: transparent;
  padding-top: 100px;
}
.footer{
  height: 280px;
  background-image:url(../assets/img/footimg.jpg);
}
.footer_center{
  width: 1140px;
  margin: 0 auto;
}
.footer_ul>li{
  margin-top: 30px;
  color: #fff;
  margin-right: 15px;
  font-size: 14px;
  cursor: pointer;
  float: left;
  list-style: none;
}
.kefu {
  color: #7dcfa0;
  float: right;
  line-height: 75px;
  font-size: 12px;
}
.footer_border_bottom{
  clear: both;
  border-bottom: 1px solid #393939;
  margin-bottom: 20px;
}
.footer_shopHouse>span{
  display: inline-block;  
  padding: 8px 10px;
  background: #121212;
  color: #888c8e;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
}
.footer_shopHouseUrl{
  padding-bottom: 20px;
  border-bottom: 1px solid #393939;
}
.footer_shopHouseUrl>span{
  color: #888c8e;
  font-size: 12px;
  display: inline-block;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.footer_certificateNumber{
  color: #999;
  margin-top: 10px;
  font-size: 12px;
}

</style>
