import http from '@/utils/http'
import qs from 'qs'
export const productgetspecialproductlist = (params) =>{
    return http.get('/product/getspecialproductlist',params)
}
export const productgetcompanyproductlist = (params) =>{
    return http.get('/product/getcompanyproductlist',params)
}
export const productgetproductbyuniqueno = (params) =>{
    return http.get('/product/getproductbyuniqueno',params)
}
export const productgetcustomdetail = (params) =>{
    return http.get('/product/getcustomdetail',params)
}
export const productgetindexcustomlist = (params) =>{
    return http.get('/product/getindexcustomlist',params)
}
export const productgetcompanycustomlist = (params) =>{
    return http.get('/product/getcompanycustomlist',params)
}
export const productgetcustomlist = (params) =>{
    return http.get('/product/getcustomlist',params)
}
export const productgetcustomscreen = (params) =>{
    return http.get('/product/getcustomscreen',params)
}
//获取产品分类
export const productgetproductcate = () =>{
    return http.get('/product/getproductcate')
}
//获取产品列表
export const productgetproductlist = (params) =>{
    return http.post('/product/getproductlist',params)
}




